import { connect } from 'react-redux';
import { getCurrentBranding } from '../../../redux/reducers/branding.reducer';
import LazyLoadCustom from '../LazyLoadCustom/LazyLoadCustom';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { Avatar, IconButton } from '@material-ui/core';
import { getUserProfile } from '../../../redux/reducers/userProfile.reducer';

const PageHeaderNew = (props) => {
  const {
    branding,
    userProfile,
    goToProfile,
    goToNotifications,
    badgeValue,
    isClientColor,
    greeting,
  } = props;
  return (
    <div
      style={
        isClientColor ? { backgroundColor: 'var(--primary-blue)' } : { backgroundColor: 'white' }
      }
      className='w-100 PageHeader__headerNew'
    >
      <div className='pr-3 pl-2 py-1 d-flex align-items-center PageHeader__headerNewContainer justify-content-between'>
        <div className='d-flex align-items-center'>
          <LazyLoadCustom
            className='PageHeader__clientLogo'
            src={branding?.branding?.client_logo}
          />
          <p className='mb-0 ml-3 PageHeader__greeting'>{greeting}</p>
        </div>
        <div className='d-flex align-items-center position-relative'>
          <IconButton onClick={goToNotifications} className='mx-1 '>
            <NotificationsNoneOutlined />
          </IconButton>
          {badgeValue ? <div className='PageHeader__notiiconBadge'>{badgeValue}</div> : null}
          <Avatar
            onClick={goToProfile}
            style={{ width: '30px', height: '30px' }}
            alt={`${userProfile.first_name} ${userProfile.last_name}`}
            src={userProfile.profile_image}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  branding: getCurrentBranding(state),
  userProfile: getUserProfile(state),
});

export default connect(mapStateToProps, null)(PageHeaderNew);
